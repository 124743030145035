<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Prescriptions Orders</h3>
          <div>
            <router-link :to="{name: 'create_prescription_order'}" class="ps-btn btn-sm"><i class="icon-cart"></i> Pay for Order</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orders" :key="prescription.id">
                  <td>{{ order.order_number }}</td>
                  <td><a href="#">{{ order.total_amount }}</a></td>
                  <td>
                      <span v-if="order.status.name === 'pending'" class="text-warning">{{ order.status.label }} </span>
                      <span v-if="order.status.name === 'paid'" class="text-success">{{ order.status.label }} </span>
                      <span v-if="order.status.name === 'approved'" class="text-danger">{{ order.status.label }} </span>
                  </td>
                  <td>
                    <router-link :to="{name: 'merchant_edit_prescriptions', params: {id: order.id}}">
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">No prescriptions orders available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="5" class="text-center m-4 text-danger">Loading prescription orders...</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "PrescriptionOrderList",
  data() {
    return {
      orders: [],
      meta: null,
      isLoading: false,
      isDeleting: false
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    getOrders() {
      this.isLoading = true;
      axios.get('orders/prescriptions').then((response) => {
        this.isLoading = false;
         this.orders = response.data.data;
         this.meta = response.data.meta;
      }).catch((error) => {
        this.isLoading = false;
         console.log(error.response);
      });
    },
    deleteOrder(id) {
      this.loading = true;
      axios.delete('orders/prescriptions/' + id).then((response) => {
        console.log(response.data);
        this.getOrders();
        if (response.status === 200 || response.status === 201) {
          location.reload();
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error.response);
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>